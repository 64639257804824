import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { AgendaOnlineDetails } from '@/constants/pageDetails';
import { buttonEvents } from '@/constants/analytics';

import agendamento from '@/assets/images/agenda-online-do-simples-dental.svg';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.simplesdental.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Agenda Online',
    },
  ],
};

const AgendaOnline = () => {
  const name = FUNCIONALIDADES.agendamento.name;

  return (
    <ImagesProvider>
      <Seo
        title="Tenha mais consultas com agenda online"
        description="Acesse sua agenda online em qualquer lugar. Controle sua agenda de forma simples, rápida e sem papel, integrada com WhatsApp e SMS."
        schema={schema}
      />
      <Root
        variant="light"
        hero={
          <Hero
            title={name}
            desc="Sem papel e com controle total de tempo"
            img={agendamento}
            alt={name}
            imageTitle="Agenda online do Simples Dental"
            gaMetadata={{ buttonEvent: buttonEvents.agendaOnline }}
            width={690}
            height={405}
          />
        }
      >
        {AgendaOnlineDetails.map((i, index) => (
          <Detail item={i} index={index} key={index} />
        ))}

        <ConhecaPlanos gaMetadata={{ buttonEvent: buttonEvents.agendaOnline }} />

        <ConhecaMais page={name} gaMetadata={{ saibaMaisButtonEvent: buttonEvents.agendaOnline }} />
      </Root>
    </ImagesProvider>
  );
};

export default AgendaOnline;
